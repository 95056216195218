<template functional>
  <svg
    width="62"
    height="61"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(.5)" fill="none" fill-rule="evenodd">
      <use fill="#000" filter="url(#a)" xlink:href="#b" />
      <use fill="#FFF" xlink:href="#b" />
      <g
        stroke="#F93"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      >
        <path
          d="M36.923 32.18a9.068 9.068 0 0 1-3.825-.84l-5.592 1.188 1.17-5.603a9.086 9.086 0 0 1-.847-3.839 9.086 9.086 0 1 1 9.085 9.085M35.12 40.193l-1.552-1.552a1.985 1.985 0 0 0-2.807 0l-1.219 1.22a.937.937 0 0 1-1.037.21c-1.768-.775-3.513-1.948-5.07-3.505-1.55-1.55-2.72-3.29-3.496-5.05a.971.971 0 0 1 .214-1.073l1.093-1.093c.888-.886.888-2.142.112-2.918l-1.55-1.55a2.646 2.646 0 0 0-3.742 0l-.863.86c-.979.981-1.389 2.394-1.125 3.797.655 3.456 2.663 7.242 5.903 10.482 3.24 3.24 7.023 5.248 10.481 5.9 1.402.264 2.816-.144 3.797-1.124l.86-.861a2.646 2.646 0 0 0 0-3.743z"
        />
      </g>
      <circle fill="#F29332" cx="32.5" cy="23.5" r="1.5" />
      <circle fill="#F29332" cx="42.5" cy="23.5" r="1.5" />
      <circle fill="#F29332" cx="37.5" cy="23.5" r="1.5" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCallMe",
};
</script>

<style></style>
